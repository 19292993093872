<template>
    <div v-if="theming" class="promo-categories-container">
        <div v-if="checkWeekDate" class="daily-categories-row">
            <mobile-promo-category
                v-for="category in localDailyCategories"
                :key="category.id"
                :category-image="category.image"
                :points-image="category.historyPointsIconImage"
                :points="getDailyPromos(category.promoType, selectedDay)"
            />
        </div>
        <mobile-wheel-category
            v-if="currentCampaign"
            :category-image="historyWheelImage"
            :date="getWheelDate(selectedDay)"
            :daily-wheel="getDailyWheels(selectedDay)"
        />
        <div v-if="checkWeekDate" class="total-daily-row">
            <div class="total-daily-container">
                <div class="image-box">
                    <span class="total-daily-title">
                        {{ $t('historyCalendar.totalDailyPoints') }}
                    </span>
                </div>
                <div class="total-daily-info">
                    <div
                        v-if="isTotalDailyPoints(totalDailyPoints[selectedDay])"
                        class="points-container"
                    >
                        <img
                            :src="theming.images.totalDailyPoints"
                            alt="points background"
                            class="points-image"
                        >
                        <span class="daily-points">{{ totalDailyPoints[selectedDay] }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="checkWeekDate" class="weekly-categories-row">
            <div class="ps-challenge promo-category">
                <mobile-promo-category
                    :category-image="getPsChallengeCategory('image')"
                    :points-image="getPsChallengeCategory('historyPointsIconImage')"
                    :points="psChallegePromos"
                />
            </div>
        </div>
        <div v-if="checkWeekDate" class="total-week-row">
            <span
                class="total-week-title"
                :style="{color: theming.colors.history.totalWeekPointsTextColor}"
            >
                {{ $t('historyCalendar.totalWeekPoints') }}
            </span>
            <div v-if="checkWeekDate" class="week-points-container">
                <img
                    :src="theming.images.totalWeekPoints"
                    alt="points background"
                    class="points-image"
                >
                <span class="total-week-points">{{ totalWeeklyPoints }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import MobileWheelCategory from '@/components/history-calendar/MobileWheelCategory'
    import MobilePromoCategory from '@/components/history-calendar/MobilePromoCategory'
    import moment from 'moment-timezone'

    export default {
        components: { MobileWheelCategory, MobilePromoCategory },
        props: {
            allCampaigns: {
                type: Array,
                required: true
            },
            selectedDay: {
                type: Number,
                default: null
            },
            selectedWeek: {
                type: Number,
                default: null
            },
            promoCategories: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                localCampaigns: [],
                currentCampaign: null,
                weekStartDate: '',
                localDailyCategories: [],
                slotsPromos: [],
                happyHourPromos: [],
                liveCasinoPromos: [],
                psBattlesPromos: [],
                totalDailyPoints: [],
                dailyWheelResults: [],
                dailyWheelDates: [],
                psChallegePromos: 0,
                totalWeeklyPoints: 0
            }
        },
        computed: {
            getDateBeforeNewImplementation() {
                return process.env.VUE_APP_DATE_BEFORE_NEW_IMPLEMENTATION
            },
            checkWeekDate() {
                return moment(this.weekStartDate).isBefore(this.getDateBeforeNewImplementation)
            },
            theming() {
                return this.$store.getters.getTheming
            },
            historyWheelImage() {
                return this.theming?.images?.historyWheelImage || ''
            }
        },
        watch: {
            selectedWeek(newValue) {
                this.currentCampaign = this.allCampaigns[newValue]
            },
            allCampaigns: {
                handler(newValue) {
                    this.currentCampaign = newValue[this.selectedWeek]
                },
                deep: true
            },
            promoCategories: {
                handler(newValue) {
                    this.localDailyCategories = newValue.filter(category => category.promoType !== 'PS_CHALLENGE')
                },
                deep: true,
                immediate: true
            },
            currentCampaign: {
                handler(newValue) {
                    this.weekStartDate = Object.keys(newValue.totalDailyPoints)[0]
                    this.totalDailyPoints = Object.values(newValue?.totalDailyPoints)
                    this.dailyWheelResults = Object.values(newValue?.dailyWheelResults)
                    this.dailyWheelDates = Object.keys(newValue?.dailyWheelResults)
                    this.totalWeeklyPoints = newValue?.totalWeeklyPoints
                    const weeklyPromos = newValue?.weeklyPromos
                    this.psChallegePromos = weeklyPromos?.psChallenge
                },
                deep: true
            }
        },
        methods: {
            getDailyPromos(promoType, selectedDay) {
                const promosObject = this.currentCampaign?.dailyPromos[promoType]
                return Object.values(promosObject)[selectedDay]
            },
            getDailyWheels(selectedDay) {
                const wheels = this.currentCampaign?.dailyWheelResults
                return Object.values(wheels)[selectedDay]
            },
            getWheelDate(selectedDay) {
                return this.dailyWheelDates[selectedDay]
            },
            getPsChallengeCategory(type) {
                return this.promoCategories.find(category => category.promoType === 'PS_CHALLENGE')?.[type]
            },
            isTotalDailyPoints(points) {
                return points > 0
            }
        }
    }
</script>

<style scoped>
.promo-categories-container,
.daily-categories-row {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: 100%;
}

.category-image-box {
    display: grid;
    place-items: center;
}

.total-daily-container {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    background: var(--ps-white);
    border: 1px solid var(--gradient-border);
}

.total-daily-container .image-box {
    display: grid;
    place-items: center;
    width: 113px;
}

.total-daily-title {
    width: 95%;
    margin: 0 auto;
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 500;
    text-align: center;
}

.total-daily-info {
    background: transparent;
    width: calc(100% - 113px);
    height: 45px;
    border-radius: 0 10px 10px 0;
    display: flex;
    justify-content: center;
}

.total-daily-info .points-container {
    width: 40px;
    height: 40px;
    position: relative;
}

.total-daily-info .points-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.daily-points {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 0.8rem;
    font-weight: 800;
    text-shadow:
        1px 1px  #eed376,
        -1px -1px #eed376,
        1px -1px #eed376,
        -1px 1px #eed376,
        2px 2px #4b3d29,
        -2px -2px #4b3d29,
        2px -2px #4b3d29,
        -2px 2px #4b3d29;
}

.week-points-container {
    position: relative;
    width: 50px;
    height: 50px;
}

.week-points-container .points-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.total-week-points {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 1rem;
    font-weight: 800;
    text-shadow:
        1px 1px  #eed376,
        -1px -1px #eed376,
        1px -1px #eed376,
        -1px 1px #eed376,
        2px 2px #4b3d29,
        -2px -2px #4b3d29,
        2px -2px #4b3d29,
        -2px 2px #4b3d29;
}

.total-week-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    width: 100%;
}

.total-week-title {
    letter-spacing: 0.5px;
    text-shadow: 0 0 4px #000;
}

</style>
