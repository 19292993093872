<template>
    <div class="promo-category-container">

        <div class="category-image-box">
            <img
                class="category-image"
                alt="category image"
                :src="localCategoryImage"
            >
        </div>
        <div class="promo-info">
            <div class="round-box">
                <div v-if="hasWheel" class="points-container">
                    <img
                        :src="localDailyWheel.thumbnailImage"
                        alt=""
                        class="points-image"
                        :class="{
                            'not-played': !result && !isTodayOrYesterdayAndIsBefore5PM(date),
                            'available-to-play': !result && isTodayOrYesterdayAndIsBefore5PM(date),
                        }"
                    >
                    <wheel-history-result
                        v-if="!!result"
                        :result="result"
                    />
                    <span v-else class="not-played-text">
                        <span
                            v-if="localDailyWheel.type === 'STANDARD'"
                            class="standard-wheel-text"
                        >
                            {{ $t('historyCalendar.standardWheel') }}
                        </span>
                        <span
                            v-if="localDailyWheel.type === 'PREMIUM'"
                            class="premium-wheel-text"
                        >
                            {{ $t('historyCalendar.premiumWheel') }}
                        </span>
                    </span>
                    <font-awesome-icon
                        v-if="!result && !isTodayOrYesterdayAndIsBefore5PM(date)"
                        class="not-played-indicator"
                        icon="fa-solid fa-times"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import dateUtils from '@/utils/date-time-utils'
    import WheelHistoryResult from '@/components/history-calendar/WheelHistoryResult.vue'
    export default {
        components: { WheelHistoryResult },
        props: {
            categoryImage: {
                type: String,
                default: ''
            },
            date: {
                type: String,
                default: ''
            },
            dailyWheel: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return {
                localDailyWheel: {
                    thumbnailImage: '',
                    type: null,
                    result: null
                }
            }
        },
        computed: {
            hasWheel() {
                return !!this.localDailyWheel?.thumbnailImage
            },
            result() {
                return this.localDailyWheel?.result
            }
        },
        watch: {
            categoryImage: {
                handler(newVal) {
                    this.localCategoryImage = newVal
                },
                immediate: true
            },
            dailyWheel: {
                handler(newValue) {
                    this.localDailyWheel = newValue
                },
                immediate: true,
                deep: true
            }
        },
        methods: {
            isTodayOrYesterdayAndIsBefore5PM(date) {
                return dateUtils.isTodayOrYesterdayAndIsBefore5(date)
            }
        }
    }
</script>

<style scoped>
.promo-category-container {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
}

.category-image-box {
    background: #000;
    width: 113px;
    height: 45px;
    border-radius: 10px 0 0 10px;
}

.category-image {
    width: 113px;
    height: 45px;
    object-fit: cover;
    border-radius: 10px 0 0 10px;
}

.promo-info {
    background: var(--main-color);
    width: calc(100% - 113px);
    height: 45px;
    border-radius: 0 10px 10px 0;
}

.round-box {
    width: 100%;
    height: 100%;
    box-shadow: 1px -1px 0 0 rgba(255, 255, 255, 0.5) inset;
    border-radius: 10px;
    display: flex;
    justify-content: center;
}

.points-container {
    position: relative;
    width: 40px;
    height: 40px;
}

.points-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.not-played-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -45%);
    height: 70%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.15rem;
    text-align: center;
}

.not-played {
    opacity: 0.3;
}

.standard-wheel-text,
.premium-wheel-text {
    font-weight: 700;
    font-size: 0.35rem;
    line-height: 1.2;
    text-align: center;
    width: 75%;
    margin: auto;
}

.standard-wheel-text {
    color: var(--ps-white);
}

.premium-wheel-text {
    color: #b49b5a;
}

.not-played-indicator {
    position: absolute;
    top: 3%;
    right: 3%;
    background: var(--ps-white);
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    color: #ce1e1e;
    opacity: 1;
}

@media screen and (min-width: 992px) {
    .not-played-text {
        transform: translate(-50%, -50%);
        width: 75%;
    }

    .standard-wheel-text,
    .premium-wheel-text {
        font-size: 0.65rem;
        margin: auto;
    }

    .not-played-indicator {
        top: 5%;
        right: 5%;
        width: 1.1rem;
        height: 1.1rem;
    }
}
</style>
