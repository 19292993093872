var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.theming)?_c('div',{staticClass:"week-component"},[_c('div',{staticClass:"day-box",style:({
            '--desktop-default-background-color': _vm.theming.colors.history.pagination.defaultBackgroundColor,
            '--desktop-default-text-color': _vm.theming.colors.history.pagination.defaultTextColor,
            '--desktop-border-color': _vm.theming.colors.history.pagination.borderColor
        })},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('historyCalendar.activationWeek'))+" ")])]),_vm._l((_vm.weekDays),function(day){return _c('div',{key:day,staticClass:"day-box",style:({
            '--desktop-default-background-color': _vm.theming.colors.history.pagination.defaultBackgroundColor,
            '--desktop-default-text-color': _vm.theming.colors.history.pagination.defaultTextColor,
            '--desktop-border-color': _vm.theming.colors.history.pagination.borderColor
        })},[_c('span',[_vm._v(" "+_vm._s(day)+" ")])])})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }