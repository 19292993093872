<template>
    <div class="week-component">
        <div class="category-image-box">
            <img
                class="category-image"
                alt="category image"
                :src="localCategoryImage"
            >
        </div>
        <desktop-day-component
            v-for="(dayPoints, index) in localDailyPoints"
            :key="index"
            :points-image="localPointsImage"
            :points="dayPoints"
            :is-star-active="dayPoints > 0"
        />
    </div>
</template>

<script>
    import DesktopDayComponent from '@/components/history-calendar/DesktopDayComponent'
    export default {
        components: { DesktopDayComponent },
        props: {
            categoryImage: {
                type: String,
                default: ''
            },
            pointsImage: {
                type: String,
                default: ''
            },
            dailyPoints: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                localCategoryImage: '',
                localPointsImage: '',
                localDailyPoints: []
            }
        },
        watch: {
            categoryImage: {
                handler(newVal) {
                    this.localCategoryImage = newVal
                },
                immediate: true
            },
            pointsImage: {
                handler(newVal) {
                    this.localPointsImage = newVal
                },
                immediate: true
            },
            dailyPoints: {
                handler(newValue) {
                    this.localDailyPoints = newValue
                },
                immediate: true,
                deep: true
            }
        }
    }
</script>

<style scoped>
.week-component {
    display: flex;
    gap: 0.25rem;
}

.category-image-box {
    background: #000;
    width: 224px;
    height: 104px;
    border-radius: 10px;
}

.category-image {
    width: 224px;
    height: 104px;
    object-fit: cover;
    border-radius: 10px;
}

</style>
