<template>
    <div class="week-component">
        <div class="category-image-box">
            <img
                class="category-image"
                alt="category image"
                :src="localCategoryImage"
            >
        </div>
        <desktop-wheel-component
            v-for="(dailyEntry, index) in localDailyWheels"
            :key="index"
            :points-image="dailyEntry && dailyEntry.thumbnailImage"
            :date="dailyDates[index]"
            :type="dailyEntry && dailyEntry.type"
            :result="getResult(dailyEntry)"
            :has-wheel="dailyEntry && !!dailyEntry.thumbnailImage"
        />
    </div>
</template>

<script>
    import DesktopWheelComponent from '@/components/history-calendar/DesktopWheelComponent'
    export default {
        components: { DesktopWheelComponent },
        props: {
            categoryImage: {
                type: String,
                default: ''
            },
            dailyDates: {
                type: Array,
                default: () => []
            },
            dailyWheels: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                localCategoryImage: '',
                localDailyWheels: [],
                localDailyDates: []
            }
        },
        watch: {
            categoryImage: {
                handler(newVal) {
                    this.localCategoryImage = newVal
                },
                immediate: true
            },
            dailyWheels: {
                handler(newValue) {
                    this.localDailyWheels = newValue
                },
                immediate: true,
                deep: true
            },
            dailyDates: {
                handler(newValue) {
                    this.localDailyDates = newValue
                },
                immediate: true
            }
        },
        methods: {
            getResult(dailyEntry) {
                return dailyEntry?.result || null
            }
        }
    }
</script>

<style scoped>
.week-component {
    display: flex;
    gap: 0.25rem;
}

.category-image-box {
    background: #000;
    width: 224px;
    height: 104px;
    border-radius: 10px;
}

.category-image {
    width: 224px;
    height: 104px;
    object-fit: cover;
    border-radius: 10px;
}

</style>
