<template>
    <div class="history-container">
        <calendar-pagination
            :all-campaigns="allCampaigns"
            :initial-selected-day="selectedDay"
            :initial-selected-week="selectedWeek"
            @selected-day-index="dayIndexUpdated"
            @selected-week-index="weekIndexUpdated"
        />
        <desktop-history
            class="desktop-only"
            :all-campaigns="allCampaigns"
            :selected-week="selectedWeek"
            :promo-categories="promoCategories"
        />
        <mobile-history
            class="mobile-only"
            :all-campaigns="allCampaigns"
            :selected-day="selectedDay"
            :selected-week="selectedWeek"
            :promo-categories="promoCategories"
        />
    </div>
</template>

<script>
    import CalendarPagination from '@/components/history-calendar/CalendarPagination'
    import MobileHistory from '@/components/history-calendar/MobileHistory'
    import DesktopHistory from '@/components/history-calendar/DesktopHistory'
    import campaignService from '@/services/campaign-service'
    import promoCategoryService from '@/services/promo-category-service'
    export default {
        components: {
            CalendarPagination,
            MobileHistory,
            DesktopHistory
        },
        data() {
            return {
                selectedDay: 0,
                selectedWeek: 0,
                allCampaigns: [],
                promoCategories: []
            }
        },
        async created() {
            const today = new Date()
            this.selectedDay = today.getDay() - 1
            const history = await campaignService.fetchPointsHistory()
            // Uncomment if we want to take promoCategories again in calendar
            this.allCampaigns = history.content
            this.selectedWeek = this.allCampaigns.length - 1
            const categories = await promoCategoryService.getPromoCategories()
            this.promoCategories = categories.content
        },
        methods: {
            dayIndexUpdated(dayIndex) {
                this.selectedDay = dayIndex
            },
            weekIndexUpdated(weekIndex) {
                this.selectedWeek = weekIndex
            }
        }
    }

</script>

<style scoped>
.history-container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 0;
}
</style>
