<template>
    <div v-if="theming" class="week-component">
        <div
            class="day-box"
            :style="{
                '--desktop-default-background-color': theming.colors.history.pagination.defaultBackgroundColor,
                '--desktop-default-text-color': theming.colors.history.pagination.defaultTextColor,
                '--desktop-border-color': theming.colors.history.pagination.borderColor
            }"
        >
            <span>
                {{ $t('historyCalendar.activationWeek') }}
            </span>
        </div>
        <div
            v-for="day in weekDays"
            :key="day"
            class="day-box"
            :style="{
                '--desktop-default-background-color': theming.colors.history.pagination.defaultBackgroundColor,
                '--desktop-default-text-color': theming.colors.history.pagination.defaultTextColor,
                '--desktop-border-color': theming.colors.history.pagination.borderColor
            }"
        >
            <span>
                {{ day }}
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                weekDays: [
                    this.$t('historyCalendar.monday'),
                    this.$t('historyCalendar.tuesday'),
                    this.$t('historyCalendar.wednesday'),
                    this.$t('historyCalendar.thursday'),
                    this.$t('historyCalendar.friday'),
                    this.$t('historyCalendar.saturday'),
                    this.$t('historyCalendar.sunday')
                ]
            }
        },
        computed: {
            theming() {
                return this.$store.getters.getTheming
            }
        }
    }
</script>

<style scoped>
.week-component {
    display: flex;
    gap: 0.25rem;
}

.day-box {
    width: 120px;
    height: 32px;
    color: var(--desktop-default-text-color);
    background: var(--desktop-default-background-color);
    border: 1px solid var(--desktop-border-color);
    font-size: 0.82rem;
    border-radius: 0.5rem;
    display: grid;
    place-items: center;
    text-align: center;
    font-weight: 700;
}

.day-box:first-child {
    width: 224px;
}

</style>
