<template>
    <span
        class="result"
    >
        <span
            v-if="result && result.points > 0"
            class="win-text points"
        >
            {{ result.points }} {{ $t('historyCalendar.points') }}
        </span>
        <span
            v-if="result && result.physicalPrizeAlias"
            class="win-text points"
        >
            {{ result.physicalPrizeAlias }}
        </span>
        <span
            v-if="result.freeSpins > 0"
            class="win-text free-spins"
        >

            {{ result.freeSpins }} FS
        </span>
        <span
            v-if="result.goldenChips > 0"
            class="win-text golden-chips"
        >
            {{ result.goldenChips }} GC
        </span>
        <span
            v-if="result.freeBets > 0"
            class="win-text free-bets"
        >

            {{ result.freeBets }} FB
        </span>
    </span>
</template>

<script>
    export default {
        props: {
            result: {
                type: Object,
                default: () => {
                    return {
                        points: 0,
                        freeSpins: 0,
                        goldenChips: 0,
                        freeBets: 0,
                        physicalPrizeAlias: ''
                    }
                }
            }
        }
    }
</script>

<style scoped>

.result {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -45%);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 30px;
}

.win-text {
    color: var(--ps-white);
    text-shadow: 0 0 5px var(--ps-black);
    font-weight: 700;
    font-size: 0.4rem;
    line-height: 1.1;
}

.win-text.plus {
    line-height: 0.9;
}

@media screen and (min-width: 992px) {
    .result {
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: unset;
        width: 70%;
        gap: 0.15rem;
    }

    .win-text {
        font-size: 0.6rem;
        line-height: 1.1;
    }
}
</style>
