var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.theming)?_c('div',{staticClass:"pagination-wrapper"},[_c('div',{staticClass:"pagination-row"},[_c('div',{staticClass:"week-btn",class:{ disabled: _vm.currentWeekIndex === 0 },style:({color: _vm.theming.colors.history.pagination.weekTextColor}),on:{"click":_vm.prevWeek}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-chevron-left"}}),_c('span',{staticClass:"week-btn-txt"},[_vm._v(" "+_vm._s(_vm.$t('historyCalendar.previousWeek'))+" ")])],1),_c('div',{staticClass:"week-indicator",style:({color: _vm.theming.colors.history.pagination.weekTextColor})},[_vm._v(" "+_vm._s(_vm.weekStartDate)+" - "+_vm._s(_vm.weekEndDate)+" ")]),_c('div',{staticClass:"week-btn",class:{ disabled: _vm.currentWeekIndex === _vm.allCampaigns.length - 1 },style:({color: _vm.theming.colors.history.pagination.weekTextColor}),on:{"click":_vm.nextWeek}},[_c('span',{staticClass:"week-btn-txt"},[_vm._v(" "+_vm._s(_vm.$t('historyCalendar.nextWeek'))+" ")]),_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-chevron-right"}})],1)]),_c('div',{staticClass:"days-row mobile-only"},_vm._l((_vm.days),function(day,index){return _c('button',{key:index,class:['day-btn', { active: _vm.selectedDayIndex === index }],style:({
                '--mobile-default-background-color': _vm.theming.colors.history.pagination.defaultBackgroundColor,
                '--mobile-default-text-color': _vm.theming.colors.history.pagination.defaultTextColor,
                '--mobile-active-background-color': _vm.theming.colors.history.pagination.currentBackgroundColor,
                '--mobile-active-text-color': _vm.theming.colors.history.pagination.currentTextColor,
                '--mobile-border-color': _vm.theming.colors.history.pagination.borderColor
            }),on:{"click":function($event){return _vm.emitDayIndex(day)}}},[_c('span',[_vm._v(" "+_vm._s(day.charAt(0))+" ")])])}),0)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }