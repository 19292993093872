<template>
    <div
        class="day-box"
        :class="{ 'light-theme': lightTheme }"
    >
        <div v-if="isStarActive" class="star-container">
            <img
                class="points-image"
                :src="pointsImage"
                alt="points image"
            >
            <span class="points">
                {{ points }}
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            pointsImage: {
                type: String,
                default: ''
            },
            points: {
                type: Number,
                default: 0
            },
            lightTheme: {
                type: Boolean,
                default: false
            },
            isStarActive: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style scoped>
.day-box {
    width: 120px;
    height: 105px;
    background: var(--main-color);
    border-radius: 6px;
    display: grid;
    place-items: center;
    box-shadow: 1px -2px 1px 0 rgba(255, 255, 255, 0.5) inset;
}

.day-box.light-theme {
    background: var(--ps-white);
    border: 1px solid var(--gradient-border);
}

.star-container {
    display: grid;
    place-items: center;
    width: 90px;
    height: 90px;
    position: relative;
}

.points-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.points {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.625rem;
    font-weight: 800;
    color: var(--ps-white);
    text-shadow:
        1px 1px  #eed376,
        -1px -1px #eed376,
        1px -1px #eed376,
        -1px 1px #eed376,
        2px 2px #4b3d29,
        -2px -2px #4b3d29,
        2px -2px #4b3d29,
        -2px 2px #4b3d29,
        3px 3px #4b3d29,
        -3px -3px #4b3d29,
        3px -3px #4b3d29,
        -3px 3px #4b3d29;
}

</style>
