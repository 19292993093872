<template>
    <div v-if="theming" class="calendar-container">
        <div class="history-calendar">
            <week-names-component />
            <div
                v-if="checkWeekDate"
                class="daily-promos-container"
            >
                <desktop-promo-category
                    v-for="category in localDailyCategories"
                    :key="category.id"
                    :category-image="category.image"
                    :points-image="category.historyPointsIconImage"
                    :daily-points="getDailyPromos(category.promoType)"
                />
            </div>
            <desktop-wheel-row
                :category-image="historyWheelImage"
                :daily-dates="dailyWheelDates"
                :daily-wheels="dailyWheelResults"
            />
            <desktop-daily-row
                v-if="checkWeekDate"
                :daily-points="totalDailyPoints"
            />
            <desktop-weekly-promo-category
                v-if="checkWeekDate"
                :category-image="getPsChallengeCategory('image')"
                :points-image="getPsChallengeCategory('historyPointsIconImage')"
                :weekly-points="psChallegePromos"
                :is-star-active="psChallegePromos > 0"
            />
            <div v-if="checkWeekDate" class="total-week-row">
                <span
                    :style="{color: theming.colors.history.totalWeekPointsTextColor}"
                    class="total-week-title"
                >
                    {{ $t('historyCalendar.totalWeekPoints') }}
                </span>
                <div class="total-points-container">
                    <img
                        :src="theming.images.totalWeekPoints"
                        alt="points background"
                        class="points-image"
                    >
                    <span class="total-week-points">{{ totalWeeklyPoints }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import WeekNamesComponent from '@/components/history-calendar/WeekNamesComponent'
    import DesktopPromoCategory from '@/components/history-calendar/DesktopPromoCategory'
    import DesktopDailyRow from '@/components/history-calendar/DesktopDailyRow'
    import DesktopWeeklyPromoCategory from '@/components/history-calendar/DesktopWeeklyPromoCategory'
    import DesktopWheelRow from '@/components/history-calendar/DesktopWheelRow'
    import moment from 'moment-timezone'
    export default {
        components: {
            DesktopPromoCategory,
            WeekNamesComponent,
            DesktopDailyRow,
            DesktopWeeklyPromoCategory,
            DesktopWheelRow
        },
        props: {
            allCampaigns: {
                type: Array,
                required: true
            },
            selectedWeek: {
                type: Number,
                default: 0
            },
            promoCategories: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                localCampaigns: [],
                currentCampaign: null,
                localDailyCategories: null,
                weekStartDate: '',
                slotsPromos: [],
                happyHourPromos: [],
                liveCasinoPromos: [],
                psBattlesPromos: [],
                totalDailyPoints: [],
                dailyWheelResults: [],
                dailyWheelDates: [],
                psChallegePromos: 0,
                totalWeeklyPoints: 0
            }
        },
        computed: {
            getDateBeforeNewImplementation() {
                return process.env.VUE_APP_DATE_BEFORE_NEW_IMPLEMENTATION
            },
            checkWeekDate() {
                return moment(this.weekStartDate).isBefore(this.getDateBeforeNewImplementation)
            },
            theming() {
                return this.$store.getters.getTheming
            },
            historyWheelImage() {
                return this.theming?.images?.historyWheelImage || ''
            }
        },
        watch: {
            selectedWeek(newValue) {
                this.currentCampaign = this.allCampaigns[newValue]
            },
            allCampaigns: {
                handler(newValue) {
                    this.currentCampaign = newValue[this.selectedWeek]
                },
                deep: true
            },
            promoCategories: {
                handler(newValue) {
                    this.localDailyCategories = newValue.filter(category => category.promoType !== 'PS_CHALLENGE')
                },
                deep: true,
                immediate: true
            },
            currentCampaign: {
                handler(newValue) {
                    this.weekStartDate = Object.keys(newValue.totalDailyPoints)[0]
                    this.totalDailyPoints = Object.values(newValue?.totalDailyPoints)
                    this.dailyWheelResults = Object.values(newValue?.dailyWheelResults)
                    this.dailyWheelDates = Object.keys(newValue?.dailyWheelResults)

                    const weeklyPromos = newValue?.weeklyPromos
                    this.psChallegePromos = weeklyPromos?.psChallenge
                    this.totalWeeklyPoints = newValue?.totalWeeklyPoints
                },
                deep: true
            }
        },
        methods: {
            getDailyPromos(promoType) {
                const promosObject = this.currentCampaign?.dailyPromos[promoType]
                return Object.values(promosObject)
            },
            getPsChallengeCategory(type) {
                return this.promoCategories.find(category => category.promoType === 'PS_CHALLENGE')?.[type]
            }
        }
    }
</script>

<style scoped>
.calendar-container {
    border-radius: 0.5rem;
    margin: 0 auto;
}

.history-calendar {
    display: flex;
    gap: 0.25rem;
    flex-direction: column;
}

.daily-promos-container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.total-week-row {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    width: 100%;
}

.total-week-title {
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-shadow: 0 0 4px #000;
}

.total-points-container {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    position: relative;
}

.total-points-container img {
    width: 104px;
    height: 104px;
    object-fit: contain;
}

.total-week-points {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.875rem;
    font-weight: 800;
    color: var(--ps-white);
    text-shadow:
        1px 1px  #eed376,
        -1px -1px #eed376,
        1px -1px #eed376,
        -1px 1px #eed376,
        2px 2px #4b3d29,
        -2px -2px #4b3d29,
        2px -2px #4b3d29,
        -2px 2px #4b3d29,
        3px 3px #4b3d29,
        -3px -3px #4b3d29,
        3px -3px #4b3d29,
        -3px 3px #4b3d29;
}

</style>
