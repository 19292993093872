<template>
    <div class="section-container">
        <route-head-row title="history" />
        <history-calendar />
    </div>
</template>

<script>

    import HistoryCalendar from '@/components/history-calendar/HistoryCalendar'
    import RouteHeadRow from '@/components/RouteHeadRow'
    const routeTitle = 'Ιστορικό Πόντων & Αποστολών | MAGICLAND Στο Casino.pamestoixima.gr'
    export default {
        components: {
            HistoryCalendar,
            RouteHeadRow
        },
        metaInfo() {
            return {
                title: routeTitle,
                meta: [
                    { name: 'robots', content: 'index, follow' },
                    { name: 'title', content: routeTitle },
                    { name: 'description', content: 'Το ιστορικό της δραστηριότητάς σου στις αποστολές της Magicland. Πόντοι, ολοκληρωμένες αποστολές και όλα όσα θέλεις να γνωρίζεις για την δραστηριότητα σου.' },
                    { property: 'og:title', content: routeTitle },
                    { property: 'og:description', content: 'Το ιστορικό της δραστηριότητάς σου στις αποστολές της Magicland. Πόντοι, ολοκληρωμένες αποστολές και όλα όσα θέλεις να γνωρίζεις για την δραστηριότητα σου.' }
                ]
            }
        },
        computed: {
            currentPoints() {
                return this.$store.getters.getCurrentSession.totalPoints
            },
            tiers() {
                return this.$store.getters.getTierList
            },
            getTheming() {
                return this.$store.getters.getTheming
            }
        }

    }
</script>

<style scoped>
.magic-wheel-position {
    position: absolute;
    top: 0.5rem;
    left: 0;
    z-index: 1;
}

</style>
