<template>
    <div class="head-row">
        <div
            v-if="isNestedRoute"
            class="back-btn mobile-only"
            @click="$router.go(-1)"
        >
            <font-awesome-icon icon="fa-solid fa-chevron-left" />
        </div>
        <h4
            v-if="theming && !isNestedRoute"
            class="route-title"
        >
            {{ theming.locale.menu[title] }}
        </h4>
        <h4
            v-if="isNestedRoute"
            class="route-title"
        >
            {{ title }}
        </h4>
        <!-- <tier-bagde
            class="tier-indicator"
            :tier="currentTier"
            :current-tier="currentTier"
        /> -->
    </div>
</template>

<script>

    // import TierBagde from '@/components/TierBagde'
    export default {
        components: {
            // TierBagde
        },
        props: {
            title: {
                type: String,
                default: ''
            },
            isNestedRoute: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            // currentTier() {
            //     return this.$store.getters.getCurrentTier
            // },
            theming() {
                return this.$store.getters.getTheming
            }
        }
    }
</script>

<style scoped>
.head-row {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    position: relative;
    max-width: 1320px;
}

.back-btn {
    color: var(--default-text-color);
    font-size: 1.5rem;
    position: absolute;
    left: 0;
    top: 45%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
}

.route-title {
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.15px;
    text-align: center;
    color: var(--route-title-text-color);
    background-color: transparent;
    background-color: var(--route-title-background-color);
    display: grid;
    place-items: center;
    padding: 0 1.5rem;
    height: 42px;
    border-radius: 50px;
    margin-bottom: 0;
}

.tier-indicator {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

@media screen and (min-width: 992px) {
    .head-row {
        padding: 1.5rem;
    }

    .route-title {
        font-size: 1.25rem;
        height: 52px;
    }
}
</style>
