<template>
    <div class="weekly-promo-category">
        <div class="category-image-box">
            <img
                class="category-image"
                alt="category image"
                :src="categoryImage"
            >
        </div>
        <div class="points-container">
            <img
                v-if="isStarActive"
                :src="pointsImage"
                alt="points background"
                class="points-image"
            >
            <span
                v-if="isStarActive"
                class="points"
            >{{ weeklyPoints }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            categoryImage: {
                type: String,
                default: ''
            },
            pointsImage: {
                type: String,
                default: ''
            },
            weeklyPoints: {
                type: Number,
                default: 0
            },
            isStarActive: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style scoped>
.weekly-promo-category {
    margin-top: 1.25rem;
    display: flex;
    gap: 0.25rem;
}

.category-image-box {
    background: #000;
    width: 224px;
    height: 104px;
    border-radius: 10px;
}

.category-image {
    width: 224px;
    height: 104px;
    object-fit: cover;
    border-radius: 10px;
}

.points-container {
    width: 100%;
    height: 100%;
    background: var(--main-color);
    border-radius: 10px;
    position: relative;
    box-shadow: 1px -2px 1px 0 rgba(255, 255, 255, 0.5) inset;
    display: grid;
    place-items: center;
}

.points-container .points-image {
    width: 90px;
    height: 90px;
    object-fit: contain;
}

.points {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.625rem;
    font-weight: 800;
    color: var(--ps-white);
    text-shadow:
        1px 1px  #eed376,
        -1px -1px #eed376,
        1px -1px #eed376,
        -1px 1px #eed376,
        2px 2px #4b3d29,
        -2px -2px #4b3d29,
        2px -2px #4b3d29,
        -2px 2px #4b3d29,
        3px 3px #4b3d29,
        -3px -3px #4b3d29,
        3px -3px #4b3d29,
        -3px 3px #4b3d29;
}
</style>
