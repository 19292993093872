<template>
    <div v-if="theming" class="pagination-wrapper">
        <div class="pagination-row">
            <div
                class="week-btn"
                :class="{ disabled: currentWeekIndex === 0 }"
                :style="{color: theming.colors.history.pagination.weekTextColor}"
                @click="prevWeek"
            >
                <font-awesome-icon icon="fa-solid fa-chevron-left" />
                <span class="week-btn-txt">
                    {{ $t('historyCalendar.previousWeek') }}
                </span>
            </div>
            <div
                class="week-indicator"
                :style="{color: theming.colors.history.pagination.weekTextColor}"
            >
                {{ weekStartDate }} - {{ weekEndDate }}
            </div>
            <div
                class="week-btn"
                :class="{ disabled: currentWeekIndex === allCampaigns.length - 1 }"
                :style="{color: theming.colors.history.pagination.weekTextColor}"
                @click="nextWeek"
            >
                <span class="week-btn-txt">
                    {{ $t('historyCalendar.nextWeek') }}
                </span>
                <font-awesome-icon icon="fa-solid fa-chevron-right" />
            </div>
        </div>
        <div class="days-row mobile-only">
            <button
                v-for="(day, index) in days"
                :key="index"
                :class="['day-btn', { active: selectedDayIndex === index }]"
                :style="{
                    '--mobile-default-background-color': theming.colors.history.pagination.defaultBackgroundColor,
                    '--mobile-default-text-color': theming.colors.history.pagination.defaultTextColor,
                    '--mobile-active-background-color': theming.colors.history.pagination.currentBackgroundColor,
                    '--mobile-active-text-color': theming.colors.history.pagination.currentTextColor,
                    '--mobile-border-color': theming.colors.history.pagination.borderColor
                }"
                @click="emitDayIndex(day)"
            >
                <span>
                    {{ day.charAt(0) }}
                </span>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            initialSelectedDay: {
                type: Number,
                required: true
            },
            initialSelectedWeek: {
                type: Number,
                required: true
            },
            allCampaigns: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                days: [
                    this.$t('historyCalendar.monday'),
                    this.$t('historyCalendar.tuesday'),
                    this.$t('historyCalendar.wednesday'),
                    this.$t('historyCalendar.thursday'),
                    this.$t('historyCalendar.friday'),
                    this.$t('historyCalendar.saturday'),
                    this.$t('historyCalendar.sunday')
                ],
                weekStartDate: null,
                weekEndDate: null,
                selectedDayIndex: 0,
                currentWeekIndex: 0
            }
        },
        computed: {
            theming() {
                return this.$store.getters.getTheming
            }
        },
        watch: {
            allCampaigns: {
                handler() {
                    this.getCurrentWeekDateRange(this.currentWeekIndex)
                },
                deep: true
            },
            initialSelectedWeek: {
                handler(newValue) {
                    this.currentWeekIndex = newValue
                    this.getCurrentWeekDateRange(this.currentWeekIndex)
                },
                immediate: true
            },
            initialSelectedDay: {
                handler(newValue) {
                    this.selectedDayIndex = newValue
                },
                immediate: true
            }
        },
        methods: {
            getCurrentWeekDateRange(weekIndex) {
                const weekData = this.allCampaigns[weekIndex]
                if (!weekData) return
                const weekStartDate = Object.keys(weekData?.totalDailyPoints)[0]
                let formatedWeekStartDate = new Date(weekStartDate)
                formatedWeekStartDate = formatedWeekStartDate.toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'numeric'
                })
                const weekEndDate = Object.keys(weekData.totalDailyPoints)[6]
                let formatedWeekEndDate = new Date(weekEndDate)
                formatedWeekEndDate = formatedWeekEndDate.toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'numeric'
                })
                this.weekStartDate = formatedWeekStartDate
                this.weekEndDate = formatedWeekEndDate
            },
            prevWeek() {
                if (this.currentWeekIndex === 0) return
                this.currentWeekIndex--
                this.getCurrentWeekDateRange(this.currentWeekIndex)
                this.emitWeekIndex()
            },
            nextWeek() {
                if (this.currentWeekIndex === this.allCampaigns.length - 1) return
                this.currentWeekIndex++
                this.getCurrentWeekDateRange(this.currentWeekIndex)
                this.emitWeekIndex()
            },
            emitDayIndex(day) {
                this.selectedDayIndex = this.days.indexOf(day)
                this.$emit('selected-day-index', this.selectedDayIndex)
            },
            emitWeekIndex() {
                this.$emit('selected-week-index', this.currentWeekIndex)
            }
        }
    }
</script>

<style scoped>
.pagination-row {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    max-width: 1092px;
    margin: 0 auto;
}

.week-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    text-shadow: 0 0 4px #000;
}

.disabled {
    opacity: 0.2;
    cursor: not-allowed;
}

.week-btn-txt {
    white-space: nowrap;
    font-size: 0.625rem;
    font-weight: 500;
}

.week-indicator {
    white-space: nowrap;
    font-size: 0.875rem;
    font-weight: 500;
    text-shadow: 0 0 4px #000;
}

.days-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.day-btn {
    background: var(--mobile-default-background-color);
    color: var(--mobile-default-text-color);
    border: 1px solid  var(--mobile-border-color);
    border-radius: 50%;
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.day-btn span {
    font-size: 0.875rem;
    font-weight: 800;
}

.day-btn.active {
    background: var(--mobile-active-background-color);
    color: var(--mobile-active-text-color);
}

@media screen and (min-width: 768px) {
    .week-btn-txt {
        font-size: 0.875rem;
    }
}
</style>
