<template>
    <div class="total-daily-row">
        <div class="week-component">
            <div class="category-title-box">
                <span class="total-daily-title">
                    {{ $t('historyCalendar.totalDailyPoints') }}
                </span>
            </div>
            <desktop-day-component
                v-for="(dayPoints, index) in dailyPoints"
                :key="index"
                :light-theme="true"
                :points-image="theming.images.totalDailyPoints"
                :points="dayPoints"
                :is-star-active="dayPoints > 0"
            />
        </div>
    </div>
</template>

<script>
    import DesktopDayComponent from '@/components/history-calendar/DesktopDayComponent'
    export default {
        components: { DesktopDayComponent },
        props: {
            dailyPoints: {
                type: Array,
                required: true
            }
        },
        computed: {
            theming() {
                return this.$store.getters.getTheming
            }
        }
    }
</script>

<style scoped>
.week-component {
    display: flex;
    gap: 0.25rem;
}

.category-title-box {
    background: var(--ps-white);
    border: 1px solid var(--gradient-border);
    width: 224px;
    height: 104px;
    display: grid;
    place-items: center;
    border-radius: 10px;
    padding: 0.5rem;
}

.total-daily-title {
    font-size: 0.82rem;
    line-height: 1.125rem;
    font-weight: 800;
    letter-spacing: 0.2208px;
    text-align: center;
}

</style>
