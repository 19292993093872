import moment from 'moment-timezone'

export default {
    getHumanizedDate(timestamp) {
        return moment.utc(timestamp).format('DD/MM/YYYY')
    },
    getGreekHumanizedDate(timestamp) {
        return this.getDateTimeInFormatAndTimezone(timestamp, 'Europe/Athens', 'YYYY-MM-DD')
    },
    getHumanizedDateTime(timestamp) {
        return this.getDateTimeInFormatAndTimezone(timestamp, 'Europe/Athens', 'DD/MM/YYYY H:mm')
    },
    getDateTimeInFormatAndTimezone(timestamp, timezone, format) {
        return moment(timestamp).tz(timezone).format(format)
    },
    isTodayOrYesterdayAndIsBefore5(date) {
        const today = moment().tz('Europe/Athens')
        const yesterday = moment().subtract(1, 'days').tz('Europe/Athens')
        const currentHour = today.hour()
        const dateToCompare = moment(date).tz('Europe/Athens')
        return today.isSame(dateToCompare, 'day') || (yesterday.isSame(dateToCompare, 'day') && currentHour < 17)
    }
}
