<template>
    <div class="promo-category-container">
        <div class="category-image-box">
            <img
                class="category-image"
                alt="category image"
                :src="localCategoryImage"
            >
        </div>
        <div class="promo-info">
            <div class="round-box">
                <div v-if="isStarActive" class="points-container">
                    <img
                        :src="localPointsImage"
                        alt="points background"
                        class="points-image"
                    >
                    <span>{{ points }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            categoryImage: {
                type: String,
                default: ''
            },
            pointsImage: {
                type: String,
                default: ''
            },
            points: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                localCategoryImage: '',
                localPointsImage: ''
            }
        },
        computed: {
            isStarActive() {
                return this.points > 0
            }
        },
        watch: {
            categoryImage: {
                handler(newVal) {
                    this.localCategoryImage = newVal
                },
                immediate: true
            },
            pointsImage: {
                handler(newVal) {
                    this.localPointsImage = newVal
                },
                immediate: true
            }

        }
    }
</script>

<style scoped>
.promo-category-container {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
}

.category-image-box {
    background: #000;
    width: 113px;
    height: 45px;
    border-radius: 10px 0 0 10px;
}

.category-image {
    width: 113px;
    height: 45px;
    object-fit: cover;
    border-radius: 10px 0 0 10px;
}

.promo-info {
    background: var(--main-color);
    width: calc(100% - 113px);
    height: 45px;
    border-radius: 0 10px 10px 0;
}

.round-box {
    width: 100%;
    height: 100%;
    box-shadow: 1px -1px 0 0 rgba(255, 255, 255, 0.5) inset;
    border-radius: 10px;
    display: flex;
    justify-content: center;
}

.points-container {
    position: relative;
    width: 40px;
    height: 40px;
}

.points-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.points-container span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 0.8rem;
    font-weight: 800;
    text-shadow:
        1px 1px  #eed376,
        -1px -1px #eed376,
        1px -1px #eed376,
        -1px 1px #eed376,
        2px 2px #4b3d29,
        -2px -2px #4b3d29,
        2px -2px #4b3d29,
        -2px 2px #4b3d29;
}
</style>
