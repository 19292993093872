<template>
    <div
        class="day-box"
    >
        <div v-if="hasWheel" class="wheel-container">
            <img
                class="points-image"
                :class="{
                    'not-played': !result && !isTodayOrYesterdayAndIsBefore5PM(date),
                    'available-to-play': !result && isTodayOrYesterdayAndIsBefore5PM(date),
                }"
                :src="pointsImage"
                alt="points image"
            >
            <wheel-history-result
                v-if="result"
                :result="result"
            />
            <span v-else class="not-played-text">
                <span
                    v-if="type === 'STANDARD'"
                    class="standard-wheel-text"
                >
                    {{ $t('historyCalendar.standardWheel') }}
                </span>
                <span
                    v-if="type === 'PREMIUM'"
                    class="premium-wheel-text"
                >
                    {{ $t('historyCalendar.premiumWheel') }}
                </span>
            </span>
            <font-awesome-icon
                v-if="!result && !isTodayOrYesterdayAndIsBefore5PM(date)"
                class="not-played-indicator"
                icon="fa-solid fa-times"
            />
        </div>
    </div>
</template>

<script>
    import dateUtils from '@/utils/date-time-utils'
    import WheelHistoryResult from '@/components/history-calendar/WheelHistoryResult.vue'
    export default {
        components: { WheelHistoryResult },
        props: {
            pointsImage: {
                type: String,
                default: ''
            },
            result: {
                type: Object,
                default: () => {
                    return {
                        points: 0,
                        freeSpins: 0,
                        goldenChips: 0,
                        freeBets: 0,
                        physicalPrizeAlias: ''
                    }
                }
            },
            lightTheme: {
                type: Boolean,
                default: false
            },
            date: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: ''
            },
            hasWheel: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            isTodayOrYesterdayAndIsBefore5PM(date) {
                return dateUtils.isTodayOrYesterdayAndIsBefore5(date)
            }
        }
    }
</script>

<style scoped>
.day-box {
    width: 120px;
    height: 105px;
    background: var(--main-color);
    border-radius: 6px;
    display: grid;
    place-items: center;
    box-shadow: 1px -2px 1px 0 rgba(255, 255, 255, 0.5) inset;
}

.day-box.light-theme {
    background: var(--ps-white);
    border: 1px solid var(--gradient-border);
}

.wheel-container {
    display: grid;
    place-items: center;
    width: 90px;
    height: 90px;
    position: relative;
}

.points-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.not-played-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 70%;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.15rem;
    text-align: center;
}

.not-played {
    opacity: 0.3;
}

.standard-wheel-text,
.premium-wheel-text {
    font-weight: 700;
    font-size: 0.65rem;
    line-height: 1.2;
    text-align: center;
    margin: auto;
}

.standard-wheel-text {
    color: var(--ps-white);
}

.premium-wheel-text {
    color: #b49b5a;
}

.not-played-indicator {
    position: absolute;
    top: 5%;
    right: 5%;
    background: var(--ps-white);
    width: 1.1rem;
    height: 1.1rem;
    border-radius: 50%;
    color: #ce1e1e;
    opacity: 1;
}
</style>
